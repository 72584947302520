<template>
  <div v-if="formAction" style="padding: 20px">
    <div v-if="multipleItemsSelectedCount" class="side-panel-selected-count">
      <p>
        Selected
        <el-tag>
          <strong>{{ multipleItemsSelectedCount }}</strong>
        </el-tag>
        items.
      </p>
    </div>
    <div v-else class="side-panel-content-info">
      <div class="field-group">
        <div class="titles">{{ __("ID") }}</div>
        <div class="details">{{ contentFormInfo.intent_id }}</div>
      </div>
      <div class="field-group">
        <div class="titles">{{ __("Name") }}</div>
        <div class="details">{{ contentFormInfo.name }}</div>
      </div>

      <div class="field-group">
        <div class="titles">{{ __("Description") }}</div>
        <div class="details">{{ contentFormInfo.description }}</div>
      </div>
    </div>
    <div style="margin-top: 20px;" class="side-panel-action-list">
      <el-dropdown
        @command="command => handleAction(command)"
        placement="bottom-start"
      >
        <el-button plain class="btn-plain">{{ __("Actions") }}</el-button>
        <el-dropdown-menu slot="dropdown" style="width: 200px;">
          <el-dropdown-item
            v-show="!multipleItemsSelectedCount"
            command="edit"
            :disabled="!can('content.intents.write')"
          >
            <span>{{ __("Edit") }}</span>
          </el-dropdown-item>
          <el-dropdown-item
            command="move"
            :disabled="!can('content.intents.write')"
          >
            <span>{{ __("Move to folder") }}</span>
          </el-dropdown-item>
          <el-dropdown-item
            v-show="!multipleItemsSelectedCount"
            command="check_in_use"
            :disabled="!!contentFormInfo.is_refreshing"
          >
            <span>{{ __("Check in use") }}</span>
          </el-dropdown-item>
          <el-dropdown-item
            v-show="!multipleItemsSelectedCount"
            command="refresh"
          >
            <span>{{ __("Refresh check in use") }}</span>
          </el-dropdown-item>

          <el-dropdown-item
            v-show="!multipleItemsSelectedCount"
            :disabled="!can('content.intents.write')"
            command="export"
          >
            <span>{{ __("Export") }}</span>
          </el-dropdown-item>
          <el-dropdown-item
            v-show="!multipleItemsSelectedCount"
            :disabled="!can('content.intents.write')"
            command="delete"
          >
            <span>{{ __("Delete") }}</span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <el-dialog
      :visible.sync="openModal"
      v-if="openModal"
      fullscreen
      :show-close="false"
      custom-class="editContentItemModal"
      destroy-on-close
      v-loading="isSubmitting"
      :modal="false"
    >
      <el-scrollbar :native="false">
        <div class="max-vh">
          <el-form
            ref="contentForm"
            :rules="rules"
            :model="contentForm"
            label-position="top"
            size="large"
            :disabled="isArchivedStatus"
          >
            <el-row type="flex" style="padding-top: 20px">
              <el-col :span="6"></el-col>
              <el-col :span="18">
                <page-header :title="__('Intent')" :content-id="this.id" />
              </el-col>
            </el-row>
            <el-row>
              <el-col
                :xs="{ span: 22, offset: 1 }"
                :sm="{ span: 20, offset: 2 }"
                :md="{ span: 16, offset: 4 }"
                :lg="{ span: 12, offset: 6 }"
              >
                <el-form-item prop="name" :label="__('Intent Name')">
                  <el-input
                    :placeholder="__('Enter Name')"
                    v-model="contentForm.name"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row type="flex">
              <el-col :span="12" :offset="6">
                <el-form-item
                  prop="description"
                  :label="__('Intent Description')"
                >
                  <el-input
                    id="intentDescField"
                    type="textarea"
                    v-model="contentForm.description"
                    maxlength="2000"
                    :autosize="{ minRows: 5, maxRows: 10 }"
                    show-word-limit
                    style="word-break: break-word"
                    :placeholder="__('Enter Description')"
                  />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row type="flex">
              <el-col :span="12" :offset="6">
                <el-collapse v-model="entitiesInputOpen">
                  <el-collapse-item name="entitiesInput">
                    <template slot="title">
                      <label class="el-form-item__label">{{
                        __("Entities")
                      }}</label>
                    </template>
                    <div class="details">
                      <el-row
                        v-for="(entity, index) in contentForm.intent_entities"
                        :key="entity.intent_entity_id"
                        style="margin-top: 15px;"
                        ><el-form-item prop="intent_entities">
                          <intent-entities-input
                            :value="entity"
                            @remove-entity="handleRemoveEntity(index)"
                            @update-entity="
                              new_entity =>
                                handleUpdateEntity(index, new_entity)
                            "
                          />
                        </el-form-item>
                      </el-row>
                      <el-button
                        plain
                        style="border: none; padding-left: 0; margin-top: 10px"
                        @click="handleAddEntity"
                      >
                        <i class="el-icon-plus" style="font-weight: bold"></i>
                        {{ __("Add Another Entity") }}
                      </el-button>
                    </div>
                  </el-collapse-item>
                </el-collapse>
              </el-col>
            </el-row>
            <el-row type="flex">
              <el-col :span="12" :offset="6">
                <intent-fulfillment-input
                  :value="fulfillmentValue"
                  :entities="contentForm.intent_entities"
                  :intentName="contentForm.name"
                  @update-fulfillment="handleUpdateFulfillmentItems"
                />
              </el-col>
            </el-row>
          </el-form>
          <div
            slot="footer"
            style="display: flex;margin-left: 25%;margin-bottom: 20px;margin-top: 20px"
          >
            <save-button
              v-if="!isArchivedStatus"
              :disabled="!contentUpdated || !can('content.intents.write')"
              type="primary"
              @click="submitForm"
              class="submitBtn"
              :primaryKey="id"
              variant="AddUpdate"
            />
            <el-button @click="handleCancel" class="cancelBtn"
              >{{ __("Cancel") }}
            </el-button>
          </div>
        </div>
      </el-scrollbar>
    </el-dialog>
    <el-dialog
      :visible.sync="importAdd"
      v-if="importAdd"
      fullscreen
      :show-close="false"
      custom-class="editContentItemModal"
      destroy-on-close
      v-loading="isSubmitting"
      :modal="false"
    >
      <el-scrollbar :native="false">
        <div class="max-vh">
          <el-row type="flex" style="padding-top: 20px">
            <el-col :span="6"></el-col>
            <el-col :span="18">
              <page-header :title="__('Intent')" :content-id="this.id" />
            </el-col>
          </el-row>
          <el-row type="flex">
            <el-col :span="12" :offset="6">
              <import-intent
                :importIntentFileUrl="importIntentFileUrl"
                @handleCancel="handleCancel"
              />
            </el-col>
          </el-row>
        </div>
      </el-scrollbar>
    </el-dialog>
  </div>
</template>

<script>
import { EventBus } from "@/EventBus";
import PageHeader from "@/components/PageHeader";
import SaveButton from "@/components/SaveButton";
import { mapActions, mapState } from "vuex";
import BaseContentInfoPanel from "@/views/build/content/mixins/BaseContentInfoPanel";

import BaseContent from "@/views/build/content/mixins/BaseContent";
import { checkUniqueName, exportIntent } from "@/api/intent";
import {
  CHECK_IN_USE_ACTION,
  DELETE_ACTION,
  EDIT_ACTION,
  EXPORT_ACTION,
  MOVE_ACTION,
  REFRESH_ACTION,
  RESTORE_ACTION
} from "@/constants/contents";
import IntentEntitiesInput from "@/views/build/content/intent/components/IntentEntitiesInput.vue";
import _ from "lodash";
import IntentFulfillmentInput from "@/views/build/content/intent/components/IntentFulfillmentInput.vue";
import ImportIntent from "@/views/build/content/intent/components/ImportIntent.vue";
import FileDownloader from "@/mixins/FileDownloader.vue";

export default {
  mixins: [BaseContentInfoPanel, BaseContent, FileDownloader],
  components: {
    IntentFulfillmentInput,
    IntentEntitiesInput,
    PageHeader,
    SaveButton,
    ImportIntent
  },
  data() {
    let validateIntentName = async (rule, value, callback) => {
      let hasError = false;
      await checkUniqueName(value)
        .then(res => {
          if (res.data.found && value !== this.contentFormInfo.name) {
            hasError = true;
          }
        })
        .catch(() => {
          hasError = false;
        });
      if (hasError) {
        callback(__("Intent name already exists"));
      } else {
        callback();
      }
    };
    let validateIntentEntities = (rule, value, callback) => {
      if (value.length > 0) {
        value.forEach(entity => {
          if (entity.name === "") {
            callback(__("Entity name is required"));
          }
          if (entity.data_type === "" || entity.data_type === undefined) {
            callback(__("Entity type is required"));
          }
          if (entity.data_type === "list" && entity.list_items.length <= 1) {
            callback(__("Entity item is required"));
          }
        });
      }
      callback();
    };
    return {
      rules: {
        name: [
          { required: true, trigger: "blur", message: __("Name is required") },
          { validator: validateIntentName, trigger: "blur" }
        ],
        intent_entities: [
          {
            validator: validateIntentEntities
          }
        ]
      },
      isSubmitting: false,
      additionalValidateRoute: "holidays",
      isContent: true,
      viewMode: "allow_read",
      forceDeleteEventName: "initiate-force-delete-Intent",
      safeDeleteEventName: "initiate-safe-delete-Intent",
      entitiesInputOpen: [],
      importIntentFileUrl: ""
    };
  },

  computed: {
    ...mapState("app", {
      selectedServiceProviderId: state => state.selectedServiceProviderId,
      selectedAccountId: state => state.selectedAccountId
    }),
    ...mapState("intents", {
      loading: state => state.loading
    }),
    ...mapState("folders", {
      selectedFolderId: state => state.selectedFolderId
    }),
    fulfillmentValue() {
      return this.contentForm.fulfillment || [];
    }
  },
  watch: {
    "contentForm.intent_entities": function(newVal) {
      if (newVal.length > 0) {
        this.entitiesInputOpen = ["entitiesInput"];
      } else {
        this.entitiesInputOpen = [];
      }
      return [];
    }
  },
  methods: {
    ...mapActions("intents", {
      createIntent: "createIntent",
      updateIntent: "updateIntent",
      deleteContentMethod: "deleteIntent",
      undoDeleteContent: "undoDeleteIntent",
      safeDeleteContent: "safeDeleteIntent",
      refreshIntent: "refreshIntent"
    }),

    submitForm() {
      this.$refs.contentForm.validate(valid => {
        if (valid) {
          this.contentForm.intent_entities = this.cleanIntentEntities(
            this.contentForm.intent_entities
          );
          this.contentForm.fulfillment = this.cleanIntentFulfillment(
            this.contentForm.fulfillment
          );
          this.isSubmitting = true;
          const process =
            this.id === -1 ? this.createIntent : this.updateIntent;
          this.contentForm.ac_id = this.selectedAccountId;
          this.contentForm.folder_id = this.selectedFolderId;
          process(this.contentForm)
            .then(data => {
              this.isSubmitting = false;
              this.id === -1
                ? this.$message({
                    type: "success",
                    message: __("Intent added Successfully")
                  })
                : this.$message({
                    type: "success",
                    message: __("Intent updated successfully")
                  });
              EventBus.$emit("list-changed", data.data);
              this.handleCancel();
            })
            .catch(err => {
              this.isSubmitting = false;
              this.$message({
                type: "error",
                message: err.message
              });
            });
        }
      });
    },

    openMoveToFolderDialog() {
      let content_ids = [this.contentForm.intent_id];
      if (this.checkedContents.length) {
        content_ids = this.checkedContents.map(item => {
          if (item && item.intent_id) {
            return item.intent_id;
          }
        });
      }

      EventBus.$emit("open-move-to-folder-modal", {
        content_ids: [...content_ids],
        content_model: "Intent"
      });
    },

    openCheckInUseDialog() {
      EventBus.$emit("open-check-in-use-modal", {
        content_model: "Intent",
        content_id: this.contentForm.intent_id
      });
    },

    refreshCheckInUse() {
      // call backend api
      EventBus.$emit("call-refresh-check-in-use-job", {
        content_model: "Intent",
        content_id: this.contentForm.intent_id,
        content_name: this.contentForm.name,
        refresh: this.refreshIntent
      });
    },

    handleAction(command) {
      switch (command) {
        case EDIT_ACTION:
          this.handleEdit();
          break;
        case DELETE_ACTION:
          this.handleDelete(
            this.contentForm,
            "Intent",
            this.contentForm.intent_id
          );
          break;
        case CHECK_IN_USE_ACTION:
          this.openCheckInUseDialog();
          break;
        case REFRESH_ACTION:
          this.refreshCheckInUse();
          break;
        case MOVE_ACTION:
          this.openMoveToFolderDialog();
          break;
        case RESTORE_ACTION:
          this.restoreContent(this.contentForm);
          break;
        case EXPORT_ACTION:
          this.handleExportIntent();
          break;
      }
    },

    handleAddEntity() {
      this.contentForm.intent_entities.push({
        name: "",
        description: "",
        type: "text",
        is_required: false,
        list_items: [],
        intent_id: this.contentForm.intent_id
      });
    },
    handleUpdateFulfillmentItems(fulfillment_items) {
      this.contentForm.fulfillment = fulfillment_items;
    },
    handleUpdateEntity(index, new_entity) {
      if (index !== -1) {
        let entity = _.nth(this.contentForm.intent_entities, index);
        if (entity) {
          entity = new_entity;
        }
      }
    },
    handleRemoveEntity(index) {
      if (index !== -1) {
        this.contentForm.intent_entities.splice(index, 1);
      }
    },

    async handleExportIntent() {
      await exportIntent(this.contentForm)
        .then(response => {
          this.downloadFile(
            response.data,
            `intent_${this.contentForm.name}_json_data.json`
          );
        })
        .catch(error => {
          console.error(error);
        });
    },

    cleanIntentEntities(intentEntities) {
      return intentEntities.map(entity => {
        if (entity.list_items && Array.isArray(entity.list_items)) {
          entity.list_items = entity.list_items.filter(
            item => item.trim() !== ""
          );
        }

        if (entity.intent_entities && Array.isArray(entity.intent_entities)) {
          entity.intent_entities = this.cleanIntentEntities(
            entity.intent_entities
          );
        }

        return entity;
      });
    },

    cleanIntentFulfillment(fulfillment) {
      if (fulfillment) {
        return fulfillment.filter(item => item.trim() !== "");
      }
      return [];
    }
  },
  created() {
    EventBus.$on(this.forceDeleteEventName, ({ content }) => {
      this.initiateDelete(content);
    });

    EventBus.$on(this.safeDeleteEventName, ({ content }) => {
      this.initiateSafeDelete(content);
    });
  },
  beforeDestroy() {
    EventBus.$off(this.forceDeleteEventName);
    EventBus.$off(this.safeDeleteEventName);
  }
};
</script>

<style scoped lang="scss">
$content-theme-color: var(--theme-color) !default;
$content-theme-hover-color: var(--theme-hover-color) !default;
$content-theme-row-hover-color: var(--theme-row-hover-color) !default;
$content-theme-outline-color: var(--theme-outline-color) !default;
$content-theme-color-white: #ffffff !default;
@import "~@/styles/content-edit-info.scss";
@import "~@/styles/node_common.scss";

::v-deep .el-select-group__title {
  color: var(--theme-color) !important;
}

::v-deep .el-select {
  .el-input.is-focus .el-input__inner {
    border-color: $content-theme-color;
  }

  .el-input__inner:hover {
    border-color: $content-theme-color;
  }

  .el-select-dropdown__item.selected {
    color: $content-theme-color;
  }

  .el-input__inner:focus {
    border-color: $content-theme-color;
  }
}

.editContentItemModal {
  padding: 0;

  .el-dialog__header {
    padding: 0;
  }

  .el-dialog__body {
    padding: 0;
    overflow: hidden;
  }

  .el-dialog__footer {
    padding: 0;
  }

  .el-collapse {
    border: none;
  }
  .el-collapse-item__header {
    border: none;
  }
  .el-collapse-item__wrap {
    border: none;
  }
}
</style>
