<template>
  <div class="intent-fulfillment">
    <div class="field-group">
      <div class="titles">{{ __("Fulfillment Responses") }}</div>
    </div>
    <el-row
      :gutter="10"
      class="entity-row"
      v-for="(item, index) in fulfillment_items"
      :key="index"
      style="margin-top: 10px;"
    >
      <el-col :span="23" :key="index">
        <span>{{ __("Response") }} {{ index + 1 }}</span>
        <input-variable-popper
          force-reinitialize
          :prepend-items="entitiesNames"
          v-model="fulfillment_items[index]"
          suffix="}}"
          onlyEntityElements
          :is-text-area="false"
          class="popper-text-area"
        />
      </el-col>
      <el-col :span="1" style="display: flex; align-items: center;">
        <div>
          <span>&nbsp;</span>
          <el-button
            plain
            v-if="displayRemoveBtn(index)"
            class="intent-fulfillment-remove-btn"
            @click="handleRemoveFulfillmentItem(index)"
          >
            <i class="el-icon-close" style="font-weight: bold"></i>
          </el-button>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import InputVariablePopper from "@/views/build/callflow/components/node-type-forms/components/InputVariablePopper.vue";

export default {
  components: { InputVariablePopper },
  props: {
    value: {
      type: Array,
      required: true
    },
    entities: {
      type: Array,
      required: true
    },
    intentName: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      fulfillment_items: []
    };
  },
  computed: {
    entitiesNames() {
      let fulfillmentVariables = Object.values(this.entities).map(
        entity => entity.name
      );
      fulfillmentVariables.push(this.intentName);
      return fulfillmentVariables;
    },
    displayRemoveBtn() {
      return index => index !== this.fulfillment_items.length - 1;
    }
  },
  methods: {
    initializeEntity() {
      this.fulfillment_items = this.value ? this.value : [];
      if (
        this.fulfillment_items.length === 0 ||
        this.fulfillment_items[this.fulfillment_items.length - 1] !== ""
      ) {
        this.fulfillment_items.push("");
      }
    },
    handleUpdateFulfillmentItems(items) {
      if (items === undefined) return;
      if (items.length > 0 && items[items.length - 1] !== "") {
        this.fulfillment_items.push("");
      }
      this.$emit("update-fulfillment", this.fulfillment_items);
    },
    handleRemoveFulfillmentItem(index) {
      this.fulfillment_items.splice(index, 1);
      this.$emit("update-fulfillment", this.fulfillment_items);
    }
  },
  watch: {
    value: {
      immediate: true,
      handler: "initializeEntity"
    },
    fulfillment_items: {
      deep: true,
      handler: "handleUpdateFulfillmentItems"
    }
  }
};
</script>

<style>
.intent-fulfillment {
  margin-top: 24px;
  padding-bottom: 50px;
}

.intent-fulfillment .titles {
  font-size: 1rem;
  color: #454545;
}

.el-button.intent-fulfillment-remove-btn {
  border: none;
  padding-left: 10px;
  padding-right: 10px;
  background: none;
  display: flex;
  align-items: center;
}

.tag-spacing {
  margin-right: 10px;
}
</style>
