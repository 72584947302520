<template>
  <div>
    <el-row :gutter="10" type="flex" class="entity-row">
      <el-col :span="16">
        <label>{{ __("Entity name") }}</label>
        <el-input
          :placeholder="__('Entity name')"
          v-model="intentEntity.name"
          @change="handleUpdateEntity"
        ></el-input>
      </el-col>
      <el-col :span="5" style="display: flex; align-items: center;">
        <div>
          <label>{{ __("Entity Type") }}</label>
          <el-radio-group
            v-model="intentEntity.data_type"
            size="small"
            @change="handleUpdateEntity"
          >
            <el-tooltip
              effect="dark"
              placement="top"
              :content="__('Text')"
              :open-delay="500"
            >
              <el-radio-button label="text">
                <font-awesome-icon :icon="['fas', 'font']" />
              </el-radio-button>
            </el-tooltip>
            <el-tooltip
              effect="dark"
              placement="top"
              :content="__('Number')"
              :open-delay="500"
            >
              <el-radio-button label="number">
                <font-awesome-icon :icon="['fas', 'hashtag']" />
              </el-radio-button>
            </el-tooltip>
            <el-tooltip
              effect="dark"
              placement="top"
              :content="__('List')"
              :open-delay="500"
            >
              <el-radio-button label="list">
                <font-awesome-icon :icon="['fas', 'list-ul']" />
              </el-radio-button>
            </el-tooltip>
          </el-radio-group>
        </div>
      </el-col>
      <el-col :span="5" style="display: flex; align-items: center;">
        <div>
          <label style="display: block;">&nbsp;</label>
          <el-switch
            v-model="intentEntity.is_required"
            :active-text="__('Is Required?')"
            @change="handleUpdateEntity"
          >
          </el-switch>
        </div>
      </el-col>
      <el-col :span="1" style="display: flex; align-items: center; ">
        <div>
          <label>&nbsp;</label>
          <el-button
            plain
            style="border: none; padding-left: 10px; padding-right: 10px"
            @click="handleRemoveEntity"
          >
            <i class="el-icon-close" style="font-weight: bold"></i>
          </el-button>
        </div>
      </el-col>
    </el-row>
    <el-row :gutter="10" class="entity-row">
      <el-col :span="24">
        <label for="intent-description-input">{{ __("Description") }}</label>
        <el-input
          id="intent-description-input"
          :placeholder="__('Description')"
          v-model="intentEntity.description"
          @change="handleUpdateEntity"
          maxlength="2000"
          :autosize="{ minRows: 5, maxRows: 10 }"
          show-word-limit
          style="word-break: break-word"
          type="textarea"
        ></el-input>
      </el-col>
    </el-row>
    <div class="intent-entity-list-item" v-show="displayList">
      <el-row :gutter="10" class="entity-row ">
        <el-col :span="24">
          <div class="titles">{{ __("List Items") }}</div>
        </el-col>
      </el-row>
      <el-row
        :gutter="10"
        class="entity-row"
        v-for="(item, index) in intentEntity.list_items"
        :key="index"
        style="margin-top: 10px;"
      >
        <el-col :span="23" :key="index">
          <label>{{ __("Item") }} {{ index + 1 }}</label>
          <el-input
            :placeholder="__('List Item')"
            v-model="intentEntity.list_items[index]"
            @input="handleUpdateEntity(index)"
            :ref="'intent-entities-input-' + index"
            @keydown.native="handleTabPress($event, index)"
          ></el-input>
        </el-col>
        <el-col :span="1" style="display: flex; align-items: center; ">
          <div>
            <label>&nbsp;</label
            ><el-button
              plain
              v-if="intentEntity.list_items[index]"
              style="border: none; padding-left: 10px; padding-right: 10px; background: none; display: flex; align-items: center;"
              @click="handleRemoveEntityItem(index)"
            >
              <i class="el-icon-close" style="font-weight: bold"></i>
            </el-button>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      intentEntity: {}
    };
  },
  computed: {
    displayList() {
      return this.intentEntity.data_type === "list";
    }
  },
  methods: {
    initializeEntity() {
      this.intentEntity = this.value;
      this.ensureListEndsWithEmpty();
    },
    handleUpdateEntity(index) {
      if (index === undefined) return;

      const isLastItem = index === this.intentEntity.list_items.length - 1;
      const isNonEmptyItem = this.intentEntity.list_items[index] !== "";

      if (isLastItem && isNonEmptyItem) {
        this.intentEntity.list_items.push("");
      }

      this.$emit("update-entity", this.intentEntity);
    },
    handleRemoveEntity() {
      this.$emit("remove-entity", this.intentEntity);
    },
    handleRemoveEntityItem(index) {
      this.intentEntity.list_items.splice(index, 1);
      this.handleUpdateEntity();
    },
    handleAddEntityItem() {
      this.intentEntity.list_items.push("");
      this.handleUpdateEntity();
    },
    ensureListEndsWithEmpty() {
      if (
        this.intentEntity.data_type === "list" &&
        this.intentEntity.list_items.length === 0
      ) {
        this.intentEntity.list_items.push("");
      } else if (this.intentEntity.data_type === "list") {
        const lastIndex = this.intentEntity.list_items.length - 1;
        if (this.intentEntity.list_items[lastIndex] !== "") {
          this.intentEntity.list_items.push("");
        }
      }
    },
    handleTabPress(event, index) {
      if (event.key === "Tab") {
        event.preventDefault();
        // Get the next input element
        const nextInputRef = this.$refs[`intent-entities-input-${index + 1}`];
        if (nextInputRef) {
          // Focus the native input element inside el-input
          nextInputRef[0].focus();
        }
      }
    }
  },
  watch: {
    value: {
      immediate: true,
      handler: "initializeEntity"
    },
    "intentEntity.data_type": function(newType) {
      if (newType === "list") {
        this.ensureListEndsWithEmpty();
      }
    }
  }
};
</script>
<style>
.intent-entity-list-item {
  margin-top: 24px;
  background-color: #f5f5f5;
  padding: 16px 32px;
}

.intent-entity-list-item .titles {
  font-size: 1rem;
  color: #454545;
}
</style>
