<template>
  <div>
    <el-form
      label-position="top"
      ref="importIntentForm"
      :model="form"
      label-width="100px"
      :rules="rules"
      v-loading="isSubmitting"
      class="form-container"
    >
      <el-row :gutter="10" class="entity-row">
        <el-col>
          <el-form-item>
            <el-button @click="downloadTemplate"
              >{{ __("Download template") }}
            </el-button>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10" class="entity-row">
        <el-col>
          <el-form-item>
            <json-uploader
              :file-name="file_name"
              :file-url="file_url"
              folder="temp"
              @on-success="handleSuccess"
              @on-delete="handleDelete"
              @on-progress="isLoading = true"
              @on-error="handleError"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row type="flex">
        <el-col :span="12">
          <el-button :disabled="isLoading" class="submitBtn" @click="submit"
            >{{ __("Submit") }}
          </el-button>
          <el-button class="cancelBtn" @click="handleCancel()"
            >{{ __("Cancel") }}
          </el-button>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import FileDownloader from "@/mixins/FileDownloader";
import { removeFiles } from "@/api/services";
import JsonUploader from "@/components/uploaders/types/JsonUploader";
import { getImportIntentTemplate, importIntent } from "@/api/intent";
import { EventBus } from "@/EventBus";

export default {
  name: "ImportIntent",
  mixins: [FileDownloader],
  components: {
    JsonUploader
  },
  props: {
    importIntentFileUrl: {
      type: String
    }
  },
  data() {
    return {
      form: {
        json_intent: ""
      },
      file_url: "",
      file_name: "",
      isLoading: false, // to disable submit button while the upload is in progress
      isSubmitting: false,
      uploadedFilePaths: [],
      rules: {
        json_intent: {
          required: true,
          message: __("Please upload a file")
        }
      }
    };
  },
  methods: {
    downloadTemplate() {
      getImportIntentTemplate().then(response => {
        this.downloadFile(response.data, `intent_import_template.json`);
      });
    },
    handleError() {
      this.isLoading = false;
    },
    handleSuccess(url) {
      this.isLoading = false;
      this.uploadedFilePaths.push(url.path);
      this.form.json_intent = url.path;
      this.file_url = url.url;
      this.file_name = url.uploaded_file_name;
    },
    handleCancel() {
      this.$emit("handleCancel");
    },
    handleDelete() {
      this.resetForm();
    },
    resetForm() {
      this.form.json_intent = "";
      this.file_url = "";
      this.file_name = "";
    },
    removeUploadedFiles() {
      removeFiles(this.uploadedFilePaths);
    },
    submit() {
      this.$refs.importIntentForm.validate(valid => {
        if (valid) {
          this.isSubmitting = true;
          importIntent(this.form)
            .then(({ data }) => {
              this.$message({
                message: data.message,
                type: "info"
              });
              EventBus.$emit("list-changed", data.data);
              this.$emit("handleCancel");
            })
            .catch(err => {
              this.$message({
                message: err.message,
                type: "error"
              });
            })
            .finally(() => {
              this.isSubmitting = false;
            });
        }
      });
    }
  },
  beforeDestroy() {
    this.removeUploadedFiles();
  }
};
</script>
