<script>
import List from "./pages/list";
import CreateOrEdit from "./pages/createOrEdit";
import BaseIndex from "@/views/build/content/mixins/BaseIndex";
import { mapState, mapActions } from "vuex";
export default {
  name: "IntentIndex",
  mixins: [BaseIndex],
  components: {
    List,
    CreateOrEdit
  },
  data() {
    return {
      selectedContent: this.initContent()
    };
  },

  computed: {
    ...mapState("intents", {
      contents: state => state.intents
    })
  },

  methods: {
    ...mapActions("intents", {
      resetIntents: "resetIntents"
    }),
    initContent() {
      return {
        name: "",
        description: "",
        intent_entities: []
      };
    }
  },

  created() {
    this.resetIntents();
  },

  mounted() {
    this.contentType = "Intent";
    this.primaryKey = "intent_id";
  }
};
</script>

<style lang="scss" scoped></style>
